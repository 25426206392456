import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import toast from "react-hot-toast";

const closeImage = require("./../../assets/Union.png");
const phoneImage = require("./../../assets/phone-call.png");
const mailImage = require("./../../assets/mail.png");

const Circle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  background: var(--Primary-80, #e88565);
  top: 40px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  cursor: pointer;
  @media (min-width: 650px) {
    display: none;
  }
`;

const MenuContainer = styled.div<{ isOpen }>`
  position: fixed;
  top: 0;
  z-index: 1000000;
  right: ${({ isOpen }) => (isOpen ? "0" : "-284px")};
  height: 100%;
  width: 284px;
  display: flex;
  background-color: #f6f6f6;
  transition: right 1s cubic-bezier(0.76, -0.01, 0.28, 1);

  box-shadow: ${({ isOpen }) =>
    isOpen
      ? "0px 0px 0px 0px rgba(0, 0, 0, 0.1), -7px 1px 16px 0px rgba(0, 0, 0, 0.1), -28px 6px 28px 0px rgba(0, 0, 0, 0.09),-63px 12px 38px 0px rgba(0, 0, 0, 0.05),-111px 22px 45px 0px rgba(0, 0, 0, 0.01),-174px 35px 50px 0px rgba(0, 0, 0, 0);"
      : "none;"};
`;

const MenuList = styled.div`
  position: absolute;
  top: 160px;
  left: 67px;
  font-family: "axiforma-thin";
  font-size: 35.079px;
  font-style: normal;
  font-weight: "lighter";
  line-height: normal;
  color: #40445c;
`;

const MenuItem = styled.p`
  cursor: pointer;
  margin-bottom: -15px;
  transition: color 0.2s;
  font-family: "axiforma-thin";
  opacity: 70%;
  padding: 1px;
  color: #40445c;
  &:hover {
    color: #e88565;
    border-bottom: 0.5px solid #e88565;
  }
`;

const Footer = styled.div`
  align-self: end;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 50px;
`;

const MenuButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLElement | null>(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (sectionId, yOffset) => {
    setMenuOpen(false);
    const section = document.getElementById(sectionId);
    if (section) {
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as HTMLElement)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    if (menuOpen) {
      window.addEventListener("click", handleDocumentClick);
    }

    return () => {
      window.removeEventListener("click", handleDocumentClick);
    };
  }, [menuOpen]);

  const handleButtonClick = () => {
    // Use window.location to initiate the phone call
    window.location.href = "tel:+48698589043";
  };
  const handleMailClick = () => {
    const email = "jawolnik@wp.pl";

    const textArea = document.createElement("textarea");
    textArea.value = email;
    textArea.style.position = "fixed"; // Make it invisible and move it off the screen
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const success = document.execCommand("copy");
      if (success) {
        console.log("Email copied to clipboard:", email);
        toast("Email został zapisany w schowku", {
          // icon: "✓",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        console.error("Unable to copy email to clipboard");
      }
    } catch (err) {
      console.error("Unable to copy email to clipboard", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <Circle onClick={toggleMenu}>
        <MenuIcon style={{ width: "80%", height: "100%", color: "white" }} />
      </Circle>
      <MenuContainer isOpen={menuOpen} ref={(node) => (menuRef.current = node)}>
        <div>
          <img
            src={closeImage}
            alt="close icon"
            onClick={toggleMenu}
            style={{ position: "absolute", right: "35px", top: "55px" }}
          />
        </div>
        <MenuList>
          <MenuItem onClick={() => handleMenuItemClick("home", 0)}>
            HOME
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("oferta", -50)}>
            OFERTA
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("kontakt", 0)}>
            ADRES
          </MenuItem>
        </MenuList>
        <Footer>
          <img src={phoneImage} onClick={handleButtonClick} />
          <img src={mailImage} onClick={handleMailClick} />
        </Footer>
      </MenuContainer>
    </>
  );
};

export default MenuButton;
