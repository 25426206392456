import React from "react";
import styled, { keyframes } from "styled-components";
// const first = require("./../../assets_marki/Loga desktop/[CITYPNG 1.png");
// const second = require("./../../assets_marki/Loga desktop/Hugo Boss svg.png");
// const third = require("./../../assets_marki/Loga desktop/KARL LAGERFELD - jpeg.png");
// const forth = require("./../../assets_marki/Loga desktop/pngwing 1.png");
// const fifth = require("./../../assets_marki/Loga desktop/pngwing 3.png");
// const sixth = require("./../../assets_marki/Loga desktop/pngwing 5.png");
// const seventh = require("./../../assets_marki/Loga desktop/tom-ford-eyewear-logo-vector 1.png");

const first = require("./../../assets_marki/Loga mobile/[CITYPNG 1.png");
const second = require("./../../assets_marki/Loga mobile/Hugo Boss svg.png");
const third = require("./../../assets_marki/Loga mobile/KARL LAGERFELD - jpeg.png");
const forth = require("./../../assets_marki/Loga mobile/pngwing 2.png");
const fifth = require("./../../assets_marki/Loga mobile/pngwing 4.png");
const sixth = require("./../../assets_marki/Loga mobile/pngwing 6.png");
const seventh = require("./../../assets_marki/Loga mobile/tom-ford-eyewear-logo-vector 2.png");
const eight = require("./../../assets_marki/Loga mobile/chloe.png");
const ninth = require("./../../assets_marki/Loga mobile/versace.png");
const tenth = require("./../../assets_marki/Loga mobile/gucci.png");
const eleventh = require("./../../assets_marki/Loga mobile/davidoff.png");

const scrollAnimation = keyframes`
0% {
  transform: translate(0, 0);
}
100% {
  transform: translate(-10000px, 0);
}
`;

const CarouselContainer = styled.div`
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  top: 88vh;
  @media (max-width: 650px) {
    top: 400px !important;
  }
  @media (max-height: 800px) and (min-width: 1300px) {
    top: 91vh;
  }
  @media (max-height: 750px) and (min-width: 1300px) {
    top: 93vh;
  }
  @media (max-height: 800px) and (min-width: 1100px) {
    top: 91vh;
  }
  // @media (max-height: 740px) and (min-width: 1300px) {
  //   display: none;
  // }
  // @media (max-height: 750px) and (min-width: 800px) {
  //   display: none;
  // }
  @media (max-height: 662px) and (min-width: 800px) {
    display: none;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  animation: ${scrollAnimation} 180s linear infinite;
`;

const Logo = styled.div`
  padding: 0 20px; /* Add spacing between logos if needed */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img`
  max-height: 100%; /* Maintain aspect ratio of logos */
`;

const Carousel = () => {
  return (
    <CarouselContainer>
      <CarouselWrapper>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
        <Logo>
          <LogoImage src={fifth} alt="Logo 5" />
        </Logo>
        <Logo>
          <LogoImage src={forth} alt="Logo 4" />
        </Logo>
        <Logo>
          <LogoImage src={first} alt="Logo 1" />
        </Logo>
        <Logo>
          <LogoImage src={second} alt="Logo 2" />
        </Logo>
        <Logo>
          <LogoImage src={third} alt="Logo 3" />
        </Logo>
        <Logo>
          <LogoImage src={sixth} alt="Logo 6" />
        </Logo>
        <Logo>
          <LogoImage src={seventh} alt="Logo 7" />
        </Logo>
        <Logo>
          <LogoImage src={eight} alt="Logo 8" />
        </Logo>
        <Logo>
          <LogoImage src={ninth} alt="Logo 9" />
        </Logo>
        <Logo>
          <LogoImage src={tenth} alt="Logo 10" />
        </Logo>
        <Logo>
          <LogoImage src={eleventh} alt="Logo 11" />
        </Logo>
      </CarouselWrapper>
    </CarouselContainer>
  );
};

export default Carousel;
