import styled from "styled-components";

export const NavbarContainer = styled.nav`
  display: flex;
  height: 80px;
  background: #fff;
  width: 100%;
  position: fixed;
  z-index: 1000000;
  justify-content: space-between;
  align-items: center;

  background: var(--Neutral, #f6f6f2);
  box-shadow: 0px 0px 0px 0px rgba(181, 181, 181, 0.1),
    0px 4px 9px 0px rgba(181, 181, 181, 0.1),
    0px 16px 16px 0px rgba(181, 181, 181, 0.09),
    0px 35px 21px 0px rgba(181, 181, 181, 0.05),
    0px 63px 25px 0px rgba(181, 181, 181, 0.01),
    0px 98px 27px 0px rgba(181, 181, 181, 0);

  @media (max-width: 650px) {
    display: none;
  }
  @media (max-height: 740px) and (min-width: 1300px) {
    height: 60px;
  }
  @media (max-height: 750px) and (min-width: 800px) {
    height: 60px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center; /* Vertically center Wolnik and Optyk */
`;
export const Left = styled.div`
  padding-left: 50px;
  display: flex;
  gap: 65px;
  align-items: end;
`;
export const LogoText = styled.div`
  font-family: "Test Domaine Display";
  font-size: 2rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.04rem;
  color: #ff8360;
`;

export const LogoTextItalic = styled.div`
  font-family: "Test Domaine Display";
  font-size: 2rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.04rem;
  color: #ff8360;
`;
export const NavOptions = styled.div`
  display: flex;
  color: #3e465b;
  font-family: "axiforma-light";
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.02625rem;
  gap: 20px;
  @media (max-width: 860px) {
    font-size: 0.75rem;
  }
  & > a {
    text-decoration: none;
    margin-right: 15px;
    color: #3e465b;
    transition: color 0.2s;

    &:hover {
      color: #ff8360; /* Add your desired hover color here */
    }
  }
`;
export const Right = styled.div`
  border-left: 0.6px solid #b7bac0;
  height: 100%;
  display: flex;
  align-items: center;
`;
export const ContactInfo = styled.div`
  color: var(--Text, #011638);
  font-family: "axiforma-light";
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.02625rem;
  padding: 0 50px;
  @media (max-width: 860px) {
    font-size: 0.75rem;
    padding: 0 27px;
  }
`;

// Combine Wolnik and Optyk into one div
export const LogoTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

// Apply margin to NavOptions instead of individual links
export const NavOptionsContainer = styled.div`
  display: flex;
`;
