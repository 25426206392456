import React, { useEffect } from "react";
import styled from "styled-components";

const Button = styled.div`
  display: inline-flex;
  height: 30px;
  padding: 14px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 40px;
  z-index: 101;
  flex-shrink: 0;
  background: var(--Secondary, #40445c);
  box-shadow: 0px 0px 0px 0px rgba(63, 69, 92, 0.15),
    0px 1px 3px 0px rgba(63, 69, 92, 0.15),
    0px 6px 6px 0px rgba(63, 69, 92, 0.13),
    0px 13px 8px 0px rgba(63, 69, 92, 0.08),
    0px 24px 10px 0px rgba(63, 69, 92, 0.02),
    0px 37px 10px 0px rgba(63, 69, 92, 0);
  @media (min-width: 650px) {
    display: none;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10000000000000000;
`;

const Text = styled.div`
  color: var(--Neutral, #f6f6f2);
  font-family: "axiforma-light";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
`;

const ContactButton = () => {
  useEffect(() => {
    const button = document.getElementById("some-action");
    console.log(button);
    const buttonRect = button!.getBoundingClientRect();

    const coloredDivs = document.querySelectorAll(".blue");
    console.log(coloredDivs);
    const areIntersecting = (bounds1, bounds2) =>
      bounds1.top < bounds2.bottom && bounds1.bottom > bounds2.top;

    const handleScroll = () => {
      for (let item of coloredDivs) {
        const itemRect = item.getBoundingClientRect();

        if (areIntersecting(itemRect, buttonRect)) {
          button!.classList.add("orange");
          button!.classList.remove("blue");

          return;
        }

        button!.classList.add("blue");
        button!.classList.remove("orange");
      }
    };

    document.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []); //
  const handleButtonClick = () => {
    // Use window.location to initiate the phone call
    window.location.href = "tel:+48698589043";
  };
  return (
    <Container>
      <Button onClick={handleButtonClick} id="some-action" className="yellow">
        <Text>Umów wizytę: +48 698 589 043</Text>
      </Button>
    </Container>
  );
};

export default ContactButton;
