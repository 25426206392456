import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./coomponents/Navbar";
import LandingPage from "./sections/LandingPage/LandingPage";
import TextSection from "sections/TextSection/TextSection";
import Footer from "sections/Footer/Footer";
import Opinie from "sections/Opinie/Opinie";
import Questions from "sections/Questions/Questions";
import Oferty from "sections/Oferta/Oferta";
import MenuButton from "coomponents/MenuButton/MenuButton";
import ContactButton from "coomponents/ContactButton/ContactButton";
import Contact from "sections/Contact/Contact";
import { PrivacyPolicy } from "sections/PrivacyPolicy/PrivacyPolicy";
import { Toaster } from "react-hot-toast";
import { WalkDiv } from "App.styles";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Toaster
                position="bottom-center"
                reverseOrder={false}
                containerStyle={{ zIndex: 999999999 }}
              />
              <MenuButton />
              <Navbar />
              <LandingPage />
              <Oferty />
              <Opinie />
              <TextSection />
              <Questions />
              <WalkDiv>
                <iframe
                  width="80%"
                  height="70%"
                  src="https://my.matterport.com/show/?m=4xDinpPMg1U"
                  frameBorder="0"
                  allowFullScreen
                  allow="xr-spatial-tracking"
                ></iframe>
              </WalkDiv>
              <Contact />
              <ContactButton />
              <Footer />
            </>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
