// TextSection.js
import React, { useState } from "react";
import styled from "styled-components";
const okoImage = require("./../../assets/Oko.png");
const okoGif = require("./../../assets/ezgif.com-gif-maker.gif");

const TextContainer = styled.h2`
  width: 95%;
  color: var(--Text, #011638);
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 171%; /* 104.31px */
  letter-spacing: 0.915px;
  text-transform: uppercase;
  text-align: left;
  margin: 60px auto;

  @media (max-width: 650px) {
    font-size: 6.8vw;
    // text-align: justify;
  }
  @media (min-width: 1000px) {
    font-size: 3.7vw;
  }
  @media (min-width: 1400px) {
    font-size: 47px;
  }
  @media (min-width: 1600px) {
    font-size: 57px;
  }
`;

const IconSpan = styled.span`
  display: inline-block;
  margin: 0 20px;
  width: 2.5rem;
`;

const Img = styled.img`
  width: 2.7rem;
  @media (max-width: 550px) {
    width: 1.7rem;
  }
  @media (max-width: 850px) {
    width: 2rem;
  }
`;

const TextSection = () => {
  const [isWindowWidthGreaterThan650, setIsWindowWidthGreaterThan650] =
    useState(window.innerWidth > 650);

  return isWindowWidthGreaterThan650 ? (
    <TextContainer>
      Z doświadczenia wiem, jak dobrze dobrane okulary dodają pewności siebie.
      Potrafią podkreślić urodę, styl i charakter swojego właściciela.
      Niezależnie od Twojej wady wzroku, możesz czuć się pewnie i pięknie w
      każdej sytuacji!
      <IconSpan>
        <Img src={okoImage} alt="Icon 1" />
      </IconSpan>
      Zbadam Twój wzrok i pomogę Ci dobrać okulary, które będziesz nosić z dumą.
      <IconSpan>
        <Img src={okoImage} alt="Icon 2" />
      </IconSpan>
      Odwiedź salon Wolnik Optyk w Gdyni, przymierz oprawki od światowych
      projektantów i odważ się zobaczyć różnicę.
    </TextContainer>
  ) : (
    <TextContainer style={{ marginTop: "-20px" }}>
      Z doświadczenia widzę, jak dobrze dobrane okulary dodają pewności siebie.
      <IconSpan></IconSpan>
      <div style={{ height: "40px" }} />
      Zbadam Twój wzrok i pomogę Ci dobrać okulary, które będziesz nosić z dumą.
      <IconSpan></IconSpan>
      <div />
      Odwiedź salon Wolnik Optyk w Gdyni, przymierz oprawki od światowych
      projektantów i odważ się zobaczyć różnicę.
    </TextContainer>
  );
};

export default TextSection;
