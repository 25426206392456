import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  margin: 60px auto;
`;

const MainTitle = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 35px;
  font-style: italic;
`;

const Section = styled.div`
  margin: 30px 0;
`;
const SectionTitle = styled.p`
  font-size: 18px;
`;
const Line = styled.p`
  font-size: 15px;
`;

const LineWithTab = styled.p`
  font-size: 15px;
  margin-left: 20px;
`;

export const PrivacyPolicy = () => {
  return (
    <Container>
      <MainTitle>Polityka prywatności</MainTitle>
      <Section>
        <SectionTitle>1. DEFINICJE </SectionTitle>
        <Line>
          <b>Administrator</b> – Zakład optyczny Jarosław Wolnik – ul. 10 Lutego
          2, 81-366 Gdynia
        </Line>
        <Line>
          <b>Dane osobowe</b> – wszystkie informacje o osobie fizycznej
          zidentyfikowanej lub możliwej do zidentyfikowania poprzez jeden bądź
          kilka szczególnych czynników określających fizyczną, fizjologiczną,
          genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną
          tożsamość, w tym IP urządzenia, dane o lokalizacji, identyfikator
          internetowy oraz informacje gromadzone za pośrednictwem plików cookie
          oraz innej podobnej technologii.
        </Line>
        <Line>
          <b>Polityka</b> – niniejsza Polityka prywatności.
        </Line>
        <Line>
          <b>RODO</b> – Rozporządzenie Parlamentu Europejskiego i Rady (UE)
          2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
          w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
        </Line>
        <Line>
          <b>Serwis</b> – serwis internetowy prowadzony przez Administratora pod
          adresem www.noveo.pl
        </Line>
        <Line>
          <b>Użytkownik</b> – każda osoba fizyczna odwiedzająca Serwis lub
          korzystająca z jednej albo kilku usług czy funkcjonalności opisanych w
          Polityce.
        </Line>
      </Section>
      <Section>
        <SectionTitle>
          2. PRZETWARZANIE DANYCH W ZWIĄZKU Z KORZYSTANIEM Z SERWISU
        </SectionTitle>
        <Line>
          W związku z korzystaniem przez Użytkownika z Serwisu Administrator
          zbiera dane w zakresie niezbędnym do kontaktu handlowego a także
          informacje o aktywności Użytkownika w Serwisie przy wykorzystaniu
          plików cookies. Poniżej zostały opisane szczegółowe zasady oraz cele
          przetwarzania danych osobowych gromadzonych podczas korzystania z
          Serwisu przez Użytkownika.
        </Line>
      </Section>
      <Section>
        <SectionTitle>3. MARKETING</SectionTitle>
        <Line>
          Administrator przetwarza dane osobowe Użytkowników w celu realizowania
          działań marketingowych, które mogą polegać na:
        </Line>
        <Line>
          kierowaniu e-mailowych powiadomień o interesujących ofertach lub
          treściach, które w niektórych przypadkach zawierają informacje
          handlowe;
        </Line>
        <Line>
          prowadzenie innego rodzaju działań związanych z marketingiem
          bezpośrednim towarów i usług (przesyłanie informacji handlowych drogą
          elektroniczną oraz działania telemarketingowe).
        </Line>
        <Line>
          W celu realizowania działań marketingowych Administrator w niektórych
          przypadkach wykorzystuje profilowanie. Oznacza to, że dzięki
          automatycznemu przetwarzaniu danych Administrator dokonuje oceny
          wybranych czynników dotyczących osób fizycznych w celu analizy ich
          zachowania lub stworzenia prognozy na przyszłość.
        </Line>
      </Section>
      <Section>
        <SectionTitle>4. MARKETING BEZPOŚREDNI</SectionTitle>
        <Line>
          Jeżeli Użytkownik wyraził zgodę na otrzymywanie informacji
          marketingowych za pośrednictwem e-mail, SMS oraz innych środków
          komunikacji elektronicznej, dane osobowe Użytkownika będą przetwarzane
          dla celu wysłania takich informacji. Podstawą przetwarzania danych
          jest uzasadniony interes NOVEO polegający na wysyłce informacji
          marketingowych w granicach udzielonej przez Użytkownika zgody
          (marketing bezpośredni). Użytkownik ma prawo sprzeciwu wobec
          przetwarzania danych na potrzeby marketingu bezpośredniego, w tym
          profilowania. Dane będą przechowywane w tym celu przez okres istnienia
          prawnie uzasadnionego interesu NOVEO, chyba że Użytkownik sprzeciwi
          się otrzymywaniu informacji marketingowych.
        </Line>
      </Section>
      <Section>
        <SectionTitle>5. PORTALE SPOŁECZNOŚĆIOWE</SectionTitle>
        <Line>
          Administrator przetwarza dane osobowe Użytkowników odwiedzających
          profile Administratora prowadzone w mediach społecznościowych
          (Facebook, YouTube, Instagram). Dane te są przetwarzane wyłącznie w
          związku z prowadzeniem profilu, w tym w celu informowania Użytkowników
          o aktywności Administratora oraz promowaniu różnego rodzaju wydarzeń,
          usług oraz produktów, a także w celu komunikacji z użytkownikami za
          pośrednictwem funkcjonalności dostępnych w mediach społecznościowych.
          Podstawą prawną przetwarzania danych osobowych przez Administratora w
          tym celu jest jego uzasadniony interes (art. 6 ust. 1 lit. f RODO)
          polegający na promowaniu własnej marki oraz budowaniu i utrzymaniu
          społeczności związanej z marką.
        </Line>
      </Section>
      <Section>
        <SectionTitle>6. PLIKI COOKIES ORAZ PODOBNA TECHNOLOGIA</SectionTitle>
        <Line>
          Pliki cookies to małe pliki tekstowe instalowane na urządzeniu
          Użytkownika przeglądającego Serwis. Cookies zbierają informacje
          ułatwiające korzystanie ze strony internetowej – np. poprzez
          zapamiętywanie odwiedzin Użytkownika w Serwisie i dokonywanych przez
          niego czynności.
        </Line>
        <Line>
          <b>COOKIES „SERWISOWE”</b>
        </Line>
        <Line>
          Administrator wykorzystuje tzw. cookie serwisowe przede wszystkim w
          celu dostarczania Użytkownikowi usług świadczonych drogą elektroniczną
          oraz poprawy jakości tych usług. W związku z tym Administrator oraz
          inne podmioty świadczące na jego rzecz usługi analityczne i
          statystyczne korzystają z plików cookies, przechowując informacje lub
          uzyskując dostęp do informacji już przechowywanych w
          telekomunikacyjnym urządzeniu końcowym Użytkownika (komputer, telefon,
          tablet itp.). Pliki cookies wykorzystywane w tym celu obejmują:
        </Line>

        <LineWithTab>
          pliki cookies z danymi wprowadzanymi przez Użytkownika (identyfikator
          sesji) na czas trwania sesji (ang. user input cookies);
        </LineWithTab>
        <LineWithTab>
          uwierzytelniające pliki cookies wykorzystywane do usług wymagających
          uwierzytelniania na czas trwania sesji (ang. authentication cookies);
        </LineWithTab>
        <LineWithTab>
          pliki cookies służące do zapewnienia bezpieczeństwa, np.
          wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania (ang.
          user centric security cookies);
        </LineWithTab>
        <LineWithTab>
          sesyjne pliki cookies odtwarzaczy multimedialnych (np. pliki cookies
          odtwarzacza flash), na czas trwania sesji (ang. multimedia player
          session cookies);
        </LineWithTab>
        <LineWithTab>
          trwałe pliki cookies służące do personalizacji interfejsu Użytkownika
          na czas trwania sesji lub nieco dłużej (ang. user interface
          customization cookies),
        </LineWithTab>
        <LineWithTab>
          pliki cookies służące do zapamiętywania zawartości koszyka na czas
          trwania sesji (ang. shopping cart cookies);
        </LineWithTab>
        <LineWithTab>
          pliki cookies służące do monitorowania ruchu na stronie internetowej,
          tj. analityki danych, w tym cookies Google Analytics (są to pliki
          wykorzystywane przez spółkę Google w celu analizy sposobu korzystania
          z Serwisu przez Użytkownika, do tworzenia statystyk i raportów
          dotyczących funkcjonowania Serwisu). Google nie wykorzystuje zebranych
          danych do identyfikacji Użytkownika ani nie łączy tych informacji w
          celu umożliwienia identyfikacji. Szczegółowe informacje o zakresie i
          zasadach zbierania danych w związku z tą usługą można znaleźć pod
          linkiem: https://www.google.com/intl/pl/policies/privacy/partners.
        </LineWithTab>
        <Line>
          <b>COOKIES „MARKETINGOWE"</b>
        </Line>
        <Line>
          Administrator wykorzystuje również pliki cookies do celów
          marketingowych, m.in. w związku z kierowaniem do Użytkowników reklamy
          behawioralnej. W tym celu Administrator przechowuje informacje lub
          uzyskuje dostęp do informacji już przechowywanych w telekomunikacyjnym
          urządzeniu końcowym Użytkownika (komputer, telefon, tablet itp.).
          Wykorzystanie plików cookies oraz zebranych za ich pośrednictwem
          danych osobowych w celach marketingowych, w szczególności w zakresie
          promowania usług i towarów podmiotów trzecich, wymaga uzyskania zgody
          Użytkownika. Zgoda ta może być wyrażona poprzez odpowiednią
          konfigurację przeglądarki, a także może zostać w każdym momencie
          wycofana, w szczególności poprzez wyczyszczenie historii cookies oraz
          wyłączenie obsługi cookies w ustawieniach przeglądarki.
        </Line>
      </Section>
      <Section>
        <SectionTitle>7. OKRES PRZETWARZANIA DANYCH OSOBOWYCH</SectionTitle>
        <Line>
          Okres przetwarzania danych przez Administratora zależy od rodzaju
          świadczonej usługi i celu przetwarzania. Co do zasady dane
          przetwarzane są przez czas świadczenia usługi lub realizowania
          zamówienia, do czasu wycofania wyrażonej zgody lub zgłoszenia
          skutecznego sprzeciwu względem przetwarzania danych w przypadkach, gdy
          podstawą prawną przetwarzania danych jest uzasadniony interes
          Administratora.
        </Line>
        <Line>
          Okres przetwarzania danych może być przedłużony w przypadku, gdy
          przetwarzanie jest niezbędne do ustalenia i dochodzenia ewentualnych
          roszczeń lub obrony przed nimi, a po tym czasie jedynie w przypadku i
          w zakresie, w jakim będą wymagać tego przepisy prawa. Po upływie
          okresu przetwarzania dane są nieodwracalnie usuwane lub anonimizowane.
        </Line>
      </Section>
      <Section>
        <SectionTitle>8. UPRAWNIENIA UŻYTKOWNIKA</SectionTitle>
        <Line>
          Osobom, których dane dotyczą, przysługują następujące prawa:
        </Line>
        <Line>
          <b>Prawo do informacji o przetwarzaniu danych osobowych</b> – na tej
          podstawie osobie zgłaszającej takie żądanie Administrator przekazuje
          informację o przetwarzaniu danych osobowych, w tym przede wszystkim o
          celach i podstawach prawnych przetwarzania, zakresie posiadanych
          danych, podmiotach, którym dane osobowe są ujawniane i planowanym
          terminie ich usunięcia;
        </Line>
        <Line>
          <b>Prawo uzyskania kopii danych</b> – na tej podstawie Administrator
          przekazuje kopię przetwarzanych danych, dotyczących osoby zgłaszającej
          żądanie;
        </Line>
        <Line>
          <b>Prawo do sprostowania</b> – na tej podstawie Administrator usuwa
          ewentualne niezgodności lub błędy dotyczące przetwarzanych danych
          osobowych, oraz uzupełnia je lub aktualizuje, jeśli są niekompletne
          lub uległy zmianie;
        </Line>
        <Line>
          <b>Prawo do usunięcia danych</b> – na tej podstawie można żądać
          usunięcia danych, których przetwarzanie nie jest już niezbędne do
          realizowania żadnego z celów, dla których zostały zebrane;
        </Line>
        <Line>
          <b>Prawo do ograniczenia przetwarzania</b> – na tej podstawie
          Administrator zaprzestaje dokonywania operacji na danych osobowych, z
          wyjątkiem operacji, na które wyraziła zgodę osoba, której dane dotyczą
          oraz ich przechowywania, zgodnie z przyjętymi zasadami retencji, lub
          dopóki nie ustaną przyczyny ograniczenia przetwarzania danych (np.
          zostanie wydana decyzji organu nadzorczego, zezwalająca na dalsze
          przetwarzanie danych);
        </Line>
        <Line>
          <b>Prawo do przenoszenia danych</b> – na tej podstawie, w zakresie w
          jakim dane są przetwarzane w związku z zawartą umową lub wyrażoną
          zgodą, Administrator wydaje dane dostarczone przez osobę, której one
          dotyczą, w formacie pozwalającym na ich odczyt przez komputer. Możliwe
          jest także zażądanie przesłania tych danych innemu podmiotowi – jednak
          pod warunkiem, że istnieją w tym zakresie techniczne możliwości
          zarówno po stronie Administratora jak i tego innego podmiotu;
        </Line>
        <Line>
          <b>
            Prawo sprzeciwu wobec przetwarzania danych w celach marketingowych
          </b>{" "}
          – osoba, której dane dotyczą, może w każdym czasie sprzeciwić się
          przetwarzaniu danych osobowych w celach marketingowych, bez
          konieczności uzasadnienia takiego sprzeciwu;
        </Line>
        <Line>
          <b>Prawo sprzeciwu wobec innych celów przetwarzania danych</b> –
          osoba, której dane dotyczą, może w każdym czasie sprzeciwić się
          przetwarzaniu danych osobowych na podstawie uzasadnionego interesu
          Administratora (np. dla celów analitycznych lub statystycznych lub ze
          względów związanych z ochroną mienia). Sprzeciw w tym zakresie
          powinien zawierać uzasadnienie oraz podlega ocenie Administratora;
        </Line>
        <Line>
          <b>Prawo wycofania zgody</b> – jeśli dane przetwarzane są na podstawie
          zgody osoba, której dane dotyczą, ma prawo ją wycofać w dowolnym
          momencie, co jednak nie wpływa na zgodność z prawem przetwarzania
          dokonanego przed wycofaniem tej zgody;
        </Line>
        <Line>
          <b>Prawo do skargi</b> – w przypadku uznania, że przetwarzanie danych
          osobowych narusza przepisy RODO lub inne przepisy dotyczące ochrony
          danych osobowych, osoba, której dane dotyczą, może złożyć skargę do
          Prezesa Urzędu Ochrony Danych Osobowych.
        </Line>
        <Line>
          Wniosek dotyczący realizacji praw podmiotów danych, można złożyć:
        </Line>
        <LineWithTab>
          w formie pisemnej na adres: Zakład optyczny Jarosław Wolnik – ul. 10
          Lutego 2, 81-366 Gdynia
        </LineWithTab>
        <LineWithTab>
          drogą e-mailową na adres: gabinet@wolnik-optyk.pl
        </LineWithTab>
        <LineWithTab>
          z jakiego uprawnienia chce skorzystać osoba składająca wniosek (np.
          prawo do otrzymania kopii danych, prawo do usunięcia danych, itd.);
        </LineWithTab>
        <LineWithTab>
          jakiego procesu przetwarzania dotyczy żądanie (np. korzystanie z
          określonej usługi, aktywność w określonym serwisie internetowym,
          otrzymywanie newslettera zawierającego informacje handlowe na
          określony adres email, itp.);
        </LineWithTab>
        <LineWithTab>
          jakich celów przetwarzania dotyczy żądanie (np. cele marketingowe,
          cele analityczne, itp.).
        </LineWithTab>
        <Line>
          Jeżeli Administrator nie będzie w stanie ustalić treści żądania lub
          zidentyfikować osoby składającej wniosek w oparciu o dokonane
          zgłoszenie, zwróci się do wnioskodawcy o dodatkowe informacje.
        </Line>
        <Line>
          Odpowiedź na zgłoszenia zostanie udzielona w ciągu miesiąca od jego
          otrzymania. W razie konieczności przedłużenia tego terminu,
          Administrator poinformuje wnioskodawcę o przyczynach takiego
          przedłużenia.
        </Line>
        <Line>
          Odpowiedź będzie udzielana na adres e-mail z którego przesłano
          wniosek, a w przypadku wniosków skierowanych listownie, listem zwykłym
          na adres wskazany przez wnioskodawcę, o ile z treści listu nie będzie
          wynikała chęć otrzymania informacji zwrotnej na adres e-mail (w takim
          przypadku należy podać adres e-mail).
        </Line>
      </Section>
      <Section>
        <SectionTitle>9. ODBIORCY DANYCH</SectionTitle>
        <Line>
          W związku z realizacją usług dane osobowe będą ujawniane zewnętrznym
          podmiotom, w tym w szczególności dostawcom odpowiedzialnym za obsługę
          systemów informatycznych, podmiotom takim jak, prawne oraz podmiotom
          powiązanym z Administratorem.
        </Line>
        <Line>
          Administrator zastrzega sobie prawo ujawnienia wybranych informacji
          dotyczących Użytkownika właściwym organom bądź osobom trzecim, które
          zgłoszą żądanie udzielenia takich informacji, opierając się na
          odpowiedniej podstawie prawnej oraz zgodnie z przepisami
          obowiązującego prawa.
        </Line>
      </Section>
      <Section>
        <SectionTitle>10. PRZEKAZYWANIE DANYCH POZA EOG</SectionTitle>
        <Line>
          Administrator nie przekazuje zbieranych danych poza obszar EOG.
        </Line>
      </Section>
      <Section>
        <SectionTitle>11. BEZPIECZEŃSTWO DANYCH OSOBOWYCH</SectionTitle>
        <Line>
          Administrator na bieżąco prowadzi analizę ryzyka w celu zapewnienia,
          że dane osobowe przetwarzane są przez niego w sposób bezpieczny –
          zapewniający przede wszystkim, że dostęp do danych mają jedynie osoby
          upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu
          na wykonywane przez nie zadania. Administrator dba o to, by wszystkie
          operacje na danych osobowych były rejestrowane i dokonywane jedynie
          przez uprawnionych pracowników i współpracowników.
        </Line>
        <Line>
          Administrator podejmuje wszelkie niezbędne działania, by także jego
          podwykonawcy i inne podmioty współpracujące dawały gwarancję
          stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy
          przetwarzają dane osobowe na zlecenie Administratora.
        </Line>
      </Section>
      <Section>
        <SectionTitle>12. DANE KONTAKTOWE</SectionTitle>
        <Line>
          Kontakt z Administratorem jest możliwy poprzez adres e-mail
          gabinet@wolnik-optyk.pl
        </Line>
      </Section>{" "}
      <Section>
        <SectionTitle>13. ZMIANA POLITYKI PRYWATNOŚCI</SectionTitle>
        <Line>
          Polityka jest na bieżąco weryfikowana i w razie potrzeby
          aktualizowana.
        </Line>
      </Section>
    </Container>
  );
};
