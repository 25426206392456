// Footer.js
import React from "react";
import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Italic } from "sections/LandingPage/LandingPage.css";
import toast from "react-hot-toast";

const FooterContainer = styled.div`
  width: 100%;
  height: 405px;
  flex-shrink: 0;
  background: var(--Primary-80, #e88565);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px -2px 5px 0px rgba(0, 0, 0, 0.1), 0px -8px 8px 0px rgba(0, 0, 0, 0.09),
    0px -19px 11px 0px rgba(0, 0, 0, 0.05),
    0px -34px 13px 0px rgba(0, 0, 0, 0.01), 0px -53px 15px 0px rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media (max-width: 650px) {
    height: 555px;
    padding-bottom: 30px;
  }
`;

const Signature = styled.p`
  color: white;
  color: var(--Neutral, #f6f6f2);
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
  padding: 40px 5px 0 0;
  font-family: "axiforma-light";
  @media (max-width: 650px) {
    font-size: 10px;
    padding: 0 5px;
  }
  @media (min-width: 1350px) {
    font-size: 12px;
  }
`;
const Description = styled.div`
  color: var(--Neutral, #f6f6f2);
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
  padding: 0 5px;
  font-family: "axiforma-light";

  @media (max-width: 650px) {
    font-size: 10px;
  }
  @media (min-width: 1350px) {
    font-size: 12px;
  }
`;
const ContentWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //   do i need this
  gap: 17%;
`;

const Title = styled.div`
  color: var(--Neutral, #f6f6f2);
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.64px;
  padding: 7px 5px;

  @media (max-width: 650px) {
    font-size: 18px;
  }
  @media (min-width: 1350px) {
    font-size: 25px;
  }
`;

const Link = styled.a`
  color: var(--Neutral, #f6f6f2);
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
  padding: 0 5px;
  font-family: "axiforma-light";
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 650px) {
    font-size: 10px;
  }
  @media (min-width: 1350px) {
    font-size: 12px;
  }
`;

const DesktopView = styled.div`
  @media (max-width: 650px) {
    display: none;
  }
`;

const MobileView = styled.div`
  @media (min-width: 650px) {
    display: none;
  }
`;

const Otwarte = styled.div`
  color: #fff;
  font-size: 26.667px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Footer = () => {
  const handleIconClick = () => {
    window.location.href = "https://www.instagram.com/wolnik.optyk/";
  };
  const handleIconFacebookClick = () => {
    window.location.href = "https://www.facebook.com/wolnik.optyk";
  };
  const handleButtonClick = () => {
    // Use window.location to initiate the phone call
    window.location.href = "tel:+48698589043";
  };
  const handleMailClick = () => {
    const email = "jawolnik@wp.pl";

    const textArea = document.createElement("textarea");
    textArea.value = email;
    textArea.style.position = "fixed"; // Make it invisible and move it off the screen
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const success = document.execCommand("copy");
      if (success) {
        console.log("Email copied to clipboard:", email);
        toast("Email został zapisany w schowku", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        console.error("Unable to copy email to clipboard");
      }
    } catch (err) {
      console.error("Unable to copy email to clipboard", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <DesktopView>
        <FooterContainer>
          <ContentWrapper>
            <Column style={{ width: "35%", paddingRight: "45px" }}>
              <div>
                <Title>
                  Wolnik<Italic style={{ marginRight: "1px" }}>Optyk</Italic>
                </Title>
                <Description>
                  Zakład Optyczny i Groomerski Wolnik Jarosław
                </Description>
                <Description>NIP: 5851053588 </Description>
                <Description>REGION: 190908692</Description>
              </div>
              <div>
                <Title>
                  O mn<Italic style={{ marginRight: "2px" }}>i</Italic>e
                </Title>
                <Description>
                  Od 40 lat dbam o lepsze widzenie moich klientów. Badam wzrok,
                  dopasowuje najlepsze szkła i oprawy światowych marek. Pomogę
                  Ci zobaczyć, jak wygląda komfort.
                </Description>
              </div>
              <div style={{ position: "relative" }}>
                <Signature>Strona wykonana przez SmoothAds Studio</Signature>
              </div>
            </Column>
            <Column style={{ width: "25%" }}>
              <div>
                <Title>
                  Ofe<Italic style={{ marginRight: "3px" }}>r</Italic>ta
                </Title>
                <Description style={{ marginBottom: "12px" }}>
                  Okulary progresywne
                </Description>
                <Description style={{ marginBottom: "12px" }}>
                  Badanie wzroku
                </Description>
                <Description style={{ marginBottom: "12px" }}>
                  Okulary korekcyjne
                </Description>
                <Description style={{ marginBottom: "12px" }}>
                  Naprawa okularów
                </Description>
                <Description style={{ marginBottom: "12px" }}>
                  Okulary przeciwsłoneczne
                </Description>
                <Description style={{ marginBottom: "12px" }}>
                  Naprawa okularów
                </Description>
              </div>
            </Column>
            <Column style={{ width: "25%" }}>
              <div>
                <Title>
                  Kont<Italic style={{ marginRight: "2px" }}>a</Italic>kt
                </Title>
                <Description
                  style={{ marginBottom: "10px" }}
                  onClick={handleButtonClick}
                >
                  +48 698 589 043
                </Description>
                <Description onClick={handleMailClick}>
                  jawolnik@wp.pl
                </Description>
              </div>
              <div>
                <Title>
                  Adr<Italic style={{ marginRight: "2px" }}>e</Italic>s
                </Title>
                <Description>10 lutego 4, 81-366</Description>
                <Description>Gdynia</Description>
              </div>
            </Column>
            <Column style={{ width: "20%" }}>
              <div>
                <Title>
                  Otwa<Italic style={{ marginRight: "3px" }}>r</Italic>te
                </Title>
                <Description>pon-pt. 11:00 - 19:00</Description>
                <Description>sob. 11:00 - 15:00</Description>
                <div style={{ padding: "15px 10px" }}>
                  <a
                    href="https://www.facebook.com/wolnik.optyk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon
                      style={{
                        color: "white",
                        transform: "scale(1.5)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleIconFacebookClick}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/wolnik.optyk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon
                      style={{
                        color: "white",
                        transform: "scale(1.5)",
                        cursor: "pointer",
                      }}
                      onClick={handleIconClick}
                    />
                  </a>
                </div>
                <Link
                  href="#"
                  target="_blank"
                  onClick={() => window.open("/privacy-policy", "_blank")}
                >
                  Polityka prywatności
                </Link>
              </div>
            </Column>
          </ContentWrapper>
        </FooterContainer>
      </DesktopView>
      <MobileView>
        <FooterContainer>
          <ContentWrapper>
            <Column style={{ width: "60%", gap: "15px" }}>
              <div>
                <Title>
                  Wolnik<Italic style={{ marginRight: "1px" }}>Optyk</Italic>
                </Title>
                <Description>
                  Zakład Optyczny i Groomerski Wolnik Jarosław
                </Description>
                <Description>NIP: 5851053588</Description>
                <Description>REGON: 190908692</Description>
              </div>
              <div>
                <Title>O mnie</Title>
                <Description>
                  Od 40 lat dbam o lepsze widzenie moich klientów. Badam wzrok,
                  dopasowuje najlepsze szkła i oprawy światowych marek. Pomogę
                  Ci zobaczyć, jak wygląda komfort.
                </Description>
              </div>

              <div>
                <Title>Oferta</Title>
                <Description>Okulary progresywne</Description>
                <Description>Badanie wzroku</Description>
                <Description>Okulary korekcyjne</Description>
                <Description>Naprawa okularów</Description>
                <Description>Okulary przeciwsłoneczne</Description>
              </div>
            </Column>
            <Column style={{ width: "38%" }}>
              <div>
                <Title>Kontakt</Title>
                <Description onClick={handleButtonClick}>
                  +48 698 589 043
                </Description>
                <Description onClick={handleMailClick}>
                  jawolnik@wp.pl
                </Description>

                <Title>Adres</Title>
                <Description>10 lutego 4, 81-366</Description>
                <Description>Gdynia</Description>

                <Otwarte>
                  Otwa<Italic style={{ marginRight: "3px" }}>r</Italic>te
                </Otwarte>
                <Description>pon-pt. 11:00 - 19:00</Description>
                <Description>sob. 11:00 - 15:00</Description>
                <div style={{ padding: "15px 6px" }}>
                  <a
                    href="https://www.facebook.com/wolnik.optyk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon
                      style={{
                        color: "white",
                        transform: "scale(1.5)",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleIconFacebookClick}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/wolnik.optyk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon
                      style={{
                        color: "white",
                        transform: "scale(1.5)",
                        cursor: "pointer",
                      }}
                      onClick={handleIconClick}
                    />
                  </a>
                </div>
                <Link
                  href="#"
                  target="_blank"
                  onClick={() => window.open("/privacy-policy", "_blank")}
                >
                  Polityka prywatności
                </Link>
                <div style={{ marginTop: "20px" }}>
                  <Signature>Strona wykonana przez SmoothAds Studio</Signature>
                </div>
              </div>
            </Column>
          </ContentWrapper>
        </FooterContainer>
      </MobileView>
    </>
  );
};

export default Footer;
