import React from "react";
import {
  NavbarContainer,
  Left,
  LogoContainer,
  LogoText,
  LogoTextItalic,
  NavOptions,
  ContactInfo,
  Right,
} from "./Navbar.css";

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <NavbarContainer>
      <Left>
        <LogoContainer>
          <LogoText>Wolnik</LogoText>
          <LogoTextItalic>Optyk</LogoTextItalic>
        </LogoContainer>
        <NavOptions>
          <a href="#oferty" onClick={() => scrollToSection("oferta")}>
            oferta
          </a>
          <a href="#badania" onClick={() => scrollToSection("pytania")}>
            badania
          </a>
          <a href="#kontakty" onClick={() => scrollToSection("kontakt")}>
            kontakt
          </a>
        </NavOptions>
      </Left>
      <Right>
        <ContactInfo>Umów wizytę: +48 698 589 043</ContactInfo>
      </Right>
    </NavbarContainer>
  );
};

export default Navbar;
