import React, { useState } from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { Italic } from "sections/LandingPage/LandingPage.css";
const bossImage = require("./../../assets/stary2.jpg");

const QuestionsContainer = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin: 100px 0;
  @media (max-width: 650px) {
    height: 110vh;
  }
  @media (max-height: 800px) {
    height: 110vh;
  }
`;

const Container = styled.div`
  width: 80%;
  height: 83%;
  background: var(--Secondary, #40445c);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 760px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1250px) {
    height: 100%;
  }
`;

const SectionTitle = styled.h2`
  color: var(--Neutral, #f6f6f2);
  font-size: 2.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -3.5%;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 650px) {
    font-size: 2rem;
    margin-top: 60px;
  }
`;

const BossImage = styled.img`
width: auto;
height: 83%;
margin-left: -120px;
flex-shrink: 0;
object-fit: cover;
border-radius: 12px;
background: lightgray 0px -130.313px / 100% 123.688% no-repeat;

/* Add a transparent border and a box-shadow for the gradient effect */
border: 1px solid transparent;
box-shadow: 0 0 0 1px linear-gradient(45deg, #FFFFFF, #2D2D2D);
box-shadow: 0px 0px 0px 1px linear-gradient(45deg, #FFFFFF, #2D2D2D); 
 @media (max-width: 1000px) {
    display: none;
  }
  @media (min-width: 1000px){
    margin-left: -50px;

  }
  @media (min-width: 1100px){
    margin-left: -90px;

  }
  @media (min-width: 1350px){
    margin-left: -200px;

  }
}`;

const QuestionsDiv = styled.div`
  width: 60%;
  font-family: "axiforma-light";

  @media (max-width: 650px) {
    width: 95%;
  }
`;

const useStyles = makeStyles({
  customAccordion: {
    "&.MuiExpansionPanel-root:before": {
      display: "none",
      backgroundColor: "transparent !important",
      height: 0,
    },
  },
  underline: {
    "& .MuiAccordionSummary-root.Mui-expanded": {
      borderBottom: "none",
    },
    "& .MuiAccordionSummary-root": {
      borderBottom: "0.5px solid rgba(246, 246, 242, 0.70) ",
      backgroundColor: "#40445c",
      color: "white",
    },
  },
});

const Questions = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <QuestionsContainer id="pytania">
      <Container className="blue">
        <SectionTitle>
          O to często mnie p<Italic style={{ marginRight: "3px" }}>y</Italic>
          ta
          <Italic>cie</Italic>
        </SectionTitle>
        <QuestionsDiv>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{
              backgroundColor: "#40445c",
              color: "white",
              border: 0,
              outline: 0,
              boxShadow: "none",
            }}
            sx={{
              "&:before": {
                boxShadow: "none",
                backgroundColor: "#40445c",
                color: "white",
                height: "0px",
                border: 0,
              },
            }}
            className={`${classes.customAccordion} ${classes.underline}`}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: expanded === "panel1" ? "#e88565" : "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                  color: expanded === "panel1" ? "#e88565" : "white",
                }}
              >
                Czy badanie jest w cenie okularów?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: "1px solid #e88565;" }}>
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                }}
                sx={{
                  color: expanded === "panel1" ? "#e7e7e7" : "transparent",
                }}
              >
                Tak. Jeśli kupujesz u nas okulary korekcyjne, badanie wzroku
                nowoczesnym sprzętem wykonamy dla Ciebie za darmo.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{
              backgroundColor: "#40445c",
              color: "white",
              border: 0,
              outline: 0,
              boxShadow: "none",
            }}
            sx={{
              "&:before": {
                boxShadow: "none",
                backgroundColor: "#40445c",
                color: "white",
                height: "0px",
                border: 0,
              },
            }}
            elevation={0}
            className={`${classes.customAccordion} ${classes.underline}`}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: expanded === "panel2" ? "#e88565" : "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                  color: expanded === "panel2" ? "#e88565" : "white",
                }}
              >
                Czy przy kilku wadach potrzebuje więcej niż 1 pary okularów?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: "1px solid #e88565;" }}>
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                }}
                sx={{
                  color: expanded === "panel2" ? "#e7e7e7" : "transparent",
                }}
              >
                Niekoniecznie. Jeśli masz problem z widzeniem tego, co blisko,
                ale też tego, co daleko, postaw na jedne okulary progresywne.
                Dzięki kilku sferom widzenia sprawdzą się one w każdej sytuacji.
                Jeśli twoja wada wzroku połączona jest z innym problem, zbadamy
                to na miejscu i dopasujemy szkła, które zagwarantują Ci pełen
                komfort.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{
              backgroundColor: "#40445c",
              color: "white",
              border: 0,
              outline: 0,
              boxShadow: "none",
            }}
            sx={{
              "&:before": {
                boxShadow: "none",
                backgroundColor: "#40445c",
                color: "white",
                height: "0px",
                border: 0,
              },
            }}
            className={`${classes.customAccordion} ${classes.underline}`}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: expanded === "panel3" ? "#e88565" : "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                  color: expanded === "panel3" ? "#e88565" : "white",
                }}
              >
                Czy szkła dla dużej wady muszą być grube?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: "1px solid #e88565;" }}>
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                }}
                sx={{
                  color: expanded === "panel3" ? "#e7e7e7" : "transparent",
                }}
              >
                Nie. Oczywiście moc szkieł i dodatkowe powłoki zwiększają
                objętość soczewki. Jednak pracując na nowoczesnym sprzęcie i
                szkłach ze świata, jesteśmy w stanie znacznie zmniejszyć grubość
                soczewki. Dzięki temu zyskuje ona na estetyczności i nie
                zniekształca optycznie oczu.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{
              backgroundColor: "#40445c",
              color: "white",
              border: 0,
              outline: 0,
              boxShadow: "none",
            }}
            sx={{
              "&:before": {
                boxShadow: "none",
                backgroundColor: "#40445c",
                color: "white",
                height: "0px",
                border: 0,
              },
            }}
            className={`${classes.customAccordion} ${classes.underline}`}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: expanded === "panel4" ? "#e88565" : "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                  color: expanded === "panel4" ? "#e88565" : "white",
                }}
              >
                Ile czasu trwa zrobienie okularów?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: "1px solid #e88565;" }}>
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                }}
                sx={{
                  color: expanded === "panel4" ? "#e7e7e7" : "transparent",
                }}
              >
                W zależności od wady wzroku, wybranych szkieł i ich dostępności
                w salonie, wykonanie Twoich nowych okularów może trwać od
                jednego dnia, do dwóch tygodni.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            style={{
              backgroundColor: "#40445c",
              color: "white",
              border: 0,
              outline: 0,
              boxShadow: "none",
            }}
            sx={{
              "&:before": {
                boxShadow: "none",
                backgroundColor: "#40445c",
                color: "white",
                height: "0px",
                border: 0,
              },
            }}
            className={`${classes.customAccordion} ${classes.underline}`}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: expanded === "panel5" ? "#e88565" : "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                  color: expanded === "panel5" ? "#e88565" : "white",
                }}
              >
                Czy mogę połączyć okulary korekcyjne i przeciwsłoneczne?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: "1px solid #e88565;" }}>
              <Typography
                style={{
                  fontFamily: "axiforma-light",
                }}
                sx={{
                  color: expanded === "panel5" ? "#e7e7e7" : "transparent",
                }}
              >
                Tak. W salonie Wolnik Optyk oferujemy kilka rozwiązań łączących
                okulary korekcyjne z ochroną przeciwsłoneczną. Możesz wybrać
                okulary przeciwsłoneczne i zamówić do nich szkła z ochroną UV,
                polaryzacją i swoją wadą. Możesz również w swoich okularach
                korekcyjnych wymienić szkła na takie, które automatycznie
                dopasowują odcień do warunków. Dodatkowo wielu producentów
                oferuje stylowe nakładki ściemniające, które jednym ruchem
                zamieniają twoje okulary korekcyjne na przeciwsłoneczne.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </QuestionsDiv>
      </Container>
      <BossImage src={bossImage} />
    </QuestionsContainer>
  );
};

export default Questions;
