import React from "react";
import toast from "react-hot-toast";
import { Italic } from "sections/LandingPage/LandingPage.css";
import styled from "styled-components";

const ContactContainer = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  font-family: "CustomFontRegular", sans-serif;
  box-shadow: -3em 0 0.9em rgba(0, 0, 0, 0.2);
  @media (max-width: 650px) {
    flex-direction: column;
    height: 140vh;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const ContactInfo = styled.div`
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  background: #3e465b;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 650px) {
    width: 100%;
    height: 60%;
  }
`;

const DeviderDiv = styled.div`
  border-bottom: 1px solid #e885654d;
  padding: 40px;
  padding-bottom: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-height: 700px) {
    padding: 20px;
    padding-bottom: 45px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Title = styled.p`
  color: var(--Primary-100, #ff8360);
  font-family: "axiforma-light";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.574px;
  margin-bottom: -20px;
  @media (min-width: 950px) {
    font-size: 15px;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  color: var(--Neutral, #f6f6f2);
  margin-bottom: -5px;
  font-weight: 300;
  line-height: 20px;
  @media (min-width: 950px) {
    font-size: 18px;
  }
  @media (min-width: 1400px) {
    font-size: 22px;
  }
`;

const Invite = styled.p`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 40px;
  margin-top: -20px;
  @media (min-width: 950px) {
    font-size: 18px;
  }
  @media (min-width: 1400px) {
    font-size: 22px;
  }
`;

const AddressLink = styled.a`
  color: var(--Neutral, #f6f6f2);
  cursor: pointer;
  font-size: 14px;
  border: 0.5px solid #f6f6f2;
  width: 135px;
  padding: 10px 10px;
  margin-top: 15px;
  font-family: "axiforma-light";
`;

const Contact = () => {
  const handleMapLinkClick = () => {
    window.open(
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.792544042146!2d18.539607076891723!3d54.5195199726527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda731b9e6debf%3A0x869ecf9e4a71f878!2sZak%C5%82ad%20optyczny%20Jaros%C5%82aw%20Wolnik!5e0!3m2!1spl!2spl!4v1695507915578!5m2!1spl!2spl",
      "_blank"
    );
  };
  const handleButtonClick = () => {
    // Use window.location to initiate the phone call
    window.location.href = "tel:+48698589043";
  };
  const handleMailClick = () => {
    const email = "jawolnik@wp.pl";

    const textArea = document.createElement("textarea");
    textArea.value = email;
    textArea.style.position = "fixed"; // Make it invisible and move it off the screen
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const success = document.execCommand("copy");
      if (success) {
        console.log("Email copied to clipboard:", email);
        toast("Email został zapisany w schowku", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        console.error("Unable to copy email to clipboard");
      }
    } catch (err) {
      console.error("Unable to copy email to clipboard", err);
    } finally {
      document.body.removeChild(textArea);
    }
  };
  return (
    <ContactContainer id="kontakt">
      <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.792544042146!2d18.539607076891723!3d54.5195199726527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda731b9e6debf%3A0x869ecf9e4a71f878!2sZak%C5%82ad%20optyczny%20Jaros%C5%82aw%20Wolnik!5e0!3m2!1spl!2spl!4v1695507915578!5m2!1spl!2spl"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          loading="lazy"
        ></iframe>
      </MapContainer>
      <ContactInfo className="blue">
        <Content>
          <DeviderDiv>
            <Title>kontakt.</Title>
            <Description onClick={handleButtonClick}>
              tel. +48 698 589 043
            </Description>
            <Description onClick={handleMailClick}>
              mail. jawolnik@wp.pl
            </Description>
          </DeviderDiv>
          <DeviderDiv>
            <Title>adres.</Title>
            <Description>10 lutego 2, 81-366 Gdynia</Description>
            <AddressLink onClick={handleMapLinkClick}>
              Wskazówki dojazdu
            </AddressLink>
          </DeviderDiv>
          <DeviderDiv style={{ border: 0 }}>
            <Title>otwarte.</Title>
            <Description>pon-pt. 11:00 - 19:00</Description>
            <Description>sob. 11:00 - 15:00</Description>
          </DeviderDiv>
          <Invite>
            Zap
            <Italic style={{ marginRight: "2px" }}>r</Italic>
            aszam.
          </Invite>
        </Content>
      </ContactInfo>
    </ContactContainer>
  );
};

export default Contact;
