// Nie przemyślałem jeszcze w jaki sposób można zrobić Responsive web design
// Propsy Width i height zostały przekazane tutaj w celu uproszczenia obliczeń(uniwersalności)
// Możesz je pominąć i jakoś to media queries robić ale nie jestem pewien jeszcze jak
// Istnieje inna opcja, że każdy obraz to tak naprawdę będzie osobny komponent, nie generyczny wtedy RWD jakoś może działać
import styled from "styled-components";

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px; /* Adjust the border radius as needed */
  overflow: hidden;
`;

const Image = styled.div<{ src: string }>`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.src}) 50% 50% no-repeat;
  background-size: 100% 100%;
  border-radius: 25px; /* Adjust the border radius as needed */
  filter: blur(5px);
`;

// Kółko które odblurowywuje obraz
const Viewer = styled.div<{ src: string; width: number; height: number }>`
  background: url(${(props) => props.src}) 50% 50% no-repeat;
  background-position: 60px 0;
  z-index: 5;
  position: absolute;
  left: -60px;
  //Tu definiujesz rozmiary kółka unblura
  //Zmieniając ten rozmiar zmień również w animacji !important
  width: 375px;
  height: 375px;
  background-size: ${(props) => props.width}px ${(props) => props.height}px;
  border-radius: 50%;
  pointer-events: none;
  border: 2px solid rgba(255, 255, 255, 0.4);
  opacity: 1;
  // //Tu wybierasz jaką chcesz animację i ile ma trwać
  animation: move3 7s ease infinite;

  @keyframes move3 {
    0% {
      //Kółko zaczyna podróż od górnego lewego rogu
      background-position: 60px 0;
      transform: translate(0, 0);
    }
    50% {
      //Tutaj definiujesz dokąd kółko podróżuje
      //Background position musi być negatywny np transform do 200px to background position do -200px
      //200px to rozmiar kółka zmieniając rozmiar kółka zmień również tutaj
      //Wzór wyliczony jest tak, żeby kółko nie wychodziło po za obraz, jeżeli chcesz inaczej możesz to zmodyfikować
      //Pamiętaj, że to kółko unblur to tak naprawdę kwadrat i punkt (0,0) to lewy górny róg !important
      background-position: 60px calc(-${(props) => props.height}px + 350px);
      transform: translate(0, calc(${(props) => props.height}px - 350px));
    }
    100% {
      //Powrót kółka do stanu początkowego
      background-position: 60px 0;
      transform: translate(0, 0);
    }
  }
`;

const Shade = styled.div<{ width: number; height: number }>`
z-index: 6;
position: absolute;
//Tu definiujesz rozmiary kółka unblura
//Zmieniając ten rozmiar zmień również w animacji !important
width: 377px;
height: 377px;
border-radius: 50%;
background-color: rgba(255,255,255,0.2);
pointer-events: none;  left: -60px;
animation: move 7s ease infinite;

  @keyframes move {
    0% {
      //Kółko zaczyna podróż od górnego lewego rogu
      background-position: 0 0;
      transform: translate(0, 0);
    }
    50% {
      //Tutaj definiujesz dokąd kółko podróżuje
      //Background position musi być negatywny np transform do 200px to background position do -200px
      //200px to rozmiar kółka zmieniając rozmiar kółka zmień również tutaj
      //Wzór wyliczony jest tak, żeby kółko nie wychodziło po za obraz, jeżeli chcesz inaczej możesz to zmodyfikować
      //Pamiętaj, że to kółko unblur to tak naprawdę kwadrat i punkt (0,0) to lewy górny róg !important
      background-position: 0 calc(-${(props) => props.height}px + 350px);
      transform: translate(0, calc(${(props) => props.height}px - 350px));
    }
    100% {
      //Powrót kółka do stanu początkowego
      background-position: 0 0;
      transform: translate(0, 0);
    }
`;

const OrangeCircle = styled.div<{ width: number; height: number }>`
  background-color: #E88565;
  z-index: -5;
  position: absolute;
  top:-45px;
  width: 467px;
  height: 467px; 
  left: -103px;
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  ease infinite;
  animation: move 7s ease infinite;
 
`;

export const Wrapper = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  // margin: 0 auto;
`;
const Rectangle = styled.div`
  width: 116px;
  height: 444px;
  flex-shrink: 0;
  background: rgba(55, 72, 100, 0.8);
  position: absolute;
  right: -40px;
  z-index: -10;
  bottom: -50px;
  @media (max-width: 650px) {
    display: none;
  }
  // @media (min-width: 1100px) {
  //   width: 90px;
  //   height: 300px;
  // }
  // @media (min-width: 1300px) {
  //   width: 90px;
  //   height: 400px;
  // }
`;

const Combiner = styled.div`
  position: relative;
  // left: -50px;
`;

export interface BluredImageProps {
  src: string;
  width: number;
  height: number;
}

export const BlurredImage = ({ src, width, height }: BluredImageProps) => {
  return (
    <Wrapper width={width} height={height}>
      <Combiner>
        <OrangeCircle width={width} height={height} />
        <Shade width={width} height={height} />

        <Viewer src={src} width={width} height={height} />
      </Combiner>
      <Rectangle />
      <ImageContainer>
        <Image src={src} />
      </ImageContainer>
    </Wrapper>
  );
};
