import styled from "styled-components";

export const WalkDiv = styled.div`
width: 100%;
height:100vh;
display:flex;
justify-content: center;
align-items:center;
margin: 20px 0 100px 0;
}}
`;
