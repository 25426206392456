import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Pagination } from "swiper/modules";
import StarIcon from "@mui/icons-material/Star";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Italic } from "sections/LandingPage/LandingPage.css";

const opinionsData = [
  {
    content:
      "Polecam to miejsce! Oryginalne oprawki, których nie ma w innych miejscach w Trójmieście! Do tego cudowna obsługa, zawsze uśmiechnięta i świetnie doradzi. Naprawa okularów szybko i tanio! Polecam :)",
    author: "Marta Michta",
  },
  {
    content:
      "Zakład godny polecenia. Pan poświęca dużo czasu i uwagi dla klienta. Jest cierpliwy, wykazuje dużą pomoc w wyborze okularów. Każdy znajdzie coś dla siebie. Warto odwiedzić tego optyka. ",
    author: "Kris Qpidur",
  },
  {
    content:
      "Bardzo dziękuję za życzliwość i cierpliwość oraz pomoc w doborze szkieł dla mojej 88-letniej Mamy. Pan optyk jest wspaniały ! Polecam państwa usługi, są na znakomitym poziomie.",
    author: "Urszula Zalewska",
  },
  {
    content:
      "Profesjonalny zakład optyczny, bardzo duży wybór okularów, świetna pomoc w dobrze. Polecam!",
    author: "Przemek Sowiński ",
  },
  {
    content:
      "Pan jest prawdziwym optykiem. Nie tylko sprzedaje okulary, ale też naprawia. Naprawił mi oprawkę, która wg sąsiadów była nie do naprawienia. Bardzo konkretny i komunikatywny zakład.",
    author: "Małgorzata Witczak",
  },
  {
    content:
      "Ogromny wybór oprawek na każdą kieszeń, profesjonalna obsługa, sympatyczna atmosfera. Polecam!",
    author: "Natalia Glinka-Hebel ",
  },
  {
    content:
      "Mistrzostwo profesjonalizmu i życzliwości! Kierowana pozytywnymi opiniami trafiłam do zakładu Pana Jarosława. Ogromnie dziękuję, polecam i po kolejną parę przyjadę specjalnie z Lublina do Gdyni. ",
    author: "Małgorzata Nowak",
  },
  {
    content:
      "Bardzo polecam chodzę tam od lat i zawsze mam świetnie dobrane okulary obsługa także wspaniała, Pan Jarek i Pani Aneta bardzo mili i za każdym razem doradzają mi w wyborze okularów.",
    author: "Julia S",
  },
  {
    content:
      "To już moje 4 okulary na przestrzeni lat, które robiłam u tego Pana. Z przyjemnością wracam do tego zakładu i z polecam każdemu, kto ceni sobie jakość. Pan Jarosław jest naprawdę dobrym specjalistą.",
    author: "Emilia Trudnowska",
  },
];

const OpinieContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  flex-shrink: 0;
  background: rgba(154, 154, 154, 0.1);
  background-color: #e88565;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  box-shadow: 0px 6px 40px rgba(154, 154, 154, 0.4),
    0px -6px 40px rgba(154, 154, 154, 0.4);
  @media (max-height: 800px) {
    padding: 100px 0;
  }
  @media (max-width: 650px) {
    height: 100vh;
  }
`;

const OpinieContainerMobile = styled.div`
  width: 100%;
  height: 60vh;
  margin-bottom:130px;
  poisiton: relative;
  }
`;

const OpinieTitle = styled.div`
  color: #ffc738;
  font-size: 4.5vw;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 650px) {
    font-size: 10vw;
    margin-bottom: 0px;
    z-index: 10000;
  }
  @media (min-width: 1400px) {
    font-size: 50px;
  }
`;

const OpinieSubtitle = styled.div`
  color: white;
  font-family: "Test Domaine Display";
  font-size: 2.5vw;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -20px;
  @media (min-width: 1400px) {
    font-size: 27px;
  }
`;

const OpinionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px 20px;
  width: 90%;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

const Opinion = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 20px;
  border: 0.5px solid #ffe5dd;
  background: var(--Neutral, #f6f6f2);
  box-shadow: 0px 0px 0px 0px rgba(63, 70, 91, 0.05),
    0px 3px 6px 0px rgba(63, 70, 91, 0.05),
    0px 10px 10px 0px rgba(63, 70, 91, 0.04),
    0px 23px 14px 0px rgba(63, 70, 91, 0.03),
    0px 41px 16px 0px rgba(63, 70, 91, 0.01),
    0px 63px 18px 0px rgba(63, 70, 91, 0);

  @media (max-width: 650px) {
    margin-left: 30px;
    margin-bottom: 40px;
  }
`;

const OrangeDiv = styled.div`
  width: 100%;
  height: 200px;
  background-color: #e88565;
  position: absolute;
  margin-top: -80px;
  z-index: -10;
`;

const OpinionAuthor = styled.div`
  color: #e88565;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 1400px) {
    font-size: 17px;
  }
  @media (max-width: 900px) {
    font-size: 1.6vw;
  }
  @media (max-width: 650px) {
    font-size: 3.5vw;
  }
`;

const OpinionContent = styled.div`
  color: black;
  font-family: "Axiforma-light";
  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  @media (min-width: 1400px) {
    font-size: 14px;
  }
  @media (max-width: 900px) {
    font-size: 1.5vw;
  }
  @media (max-width: 650px) {
    font-size: 3vw;
  }
  @media (max-width: 450px) {
    font-size: 3.4vw;
  }
`;

const LastText = styled.div`
  font-size: 14px;
  color: white;
  @media (min-width: 1400px) {
    font-size: 16px;
  }
`;

const Stars = styled.div`
  @media (max-width: 1000px) {
    transform: scale(0.9);
  }
`;

const Opinie = () => {
  const [expanded, setExpanded] = useState(false);
  const [isWindowWidthGreaterThan650, setIsWindowWidthGreaterThan650] =
    useState(window.innerWidth > 650);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    setIsWindowWidthGreaterThan650(window.innerWidth > 650);
  }, []);

  return (
    <>
      {isWindowWidthGreaterThan650 ? (
        <OpinieContainer onClick={toggleExpansion}>
          <OpinieTitle>
            O<Italic style={{ marginRight: "-3px" }}>p</Italic>inie
          </OpinieTitle>
          <OpinieSubtitle>
            Przeczytaj co piszą o mnie moi klienci:
          </OpinieSubtitle>
          <OpinionsWrapper>
            {(window.innerWidth < 900
              ? opinionsData.slice(0, 6)
              : opinionsData
            ).map((opinion, index) => (
              <Opinion key={index}>
                <OpinionContent>{opinion.content}</OpinionContent>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <OpinionAuthor>{opinion.author}</OpinionAuthor>
                  <Stars
                    style={{
                      display: "flex",
                      gap: "0",
                    }}
                  >
                    <StarIcon style={{ color: " #ffc738" }} />
                    <StarIcon style={{ color: " #ffc738" }} />
                    <StarIcon style={{ color: " #ffc738" }} />
                    <StarIcon style={{ color: " #ffc738" }} />
                    <StarIcon style={{ color: " #ffc738" }} />
                  </Stars>
                </div>
              </Opinion>
            ))}
          </OpinionsWrapper>
          <LastText style={{ marginTop: "20px" }}>
            Mam nadzieję, że dołączysz do grona moich klientów.
          </LastText>
        </OpinieContainer>
      ) : (
        <OpinieContainerMobile>
          <OpinieTitle>Opinie</OpinieTitle>
          <OrangeDiv />
          <Swiper
            style={{ overflowX: "hidden", zIndex: "0" }}
            modules={[Pagination]}
            spaceBetween={10}
            slidesPerView={1.2}
            pagination={{ clickable: true }}
          >
            {opinionsData.map((opinion, index) => (
              <SwiperSlide>
                <Opinion key={index}>
                  <OpinionContent>{opinion.content}</OpinionContent>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                    }}
                  >
                    <OpinionAuthor>{opinion.author}</OpinionAuthor>
                    <Stars
                      style={{
                        display: "flex",
                        gap: "0",
                      }}
                    >
                      <StarIcon style={{ color: " #ffc738" }} />
                      <StarIcon style={{ color: " #ffc738" }} />
                      <StarIcon style={{ color: " #ffc738" }} />
                      <StarIcon style={{ color: " #ffc738" }} />
                      <StarIcon style={{ color: " #ffc738" }} />
                    </Stars>
                  </div>
                </Opinion>
              </SwiperSlide>
            ))}
          </Swiper>
        </OpinieContainerMobile>
      )}
    </>
  );
};

export default Opinie;
